import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'
import { useBeforeUnload } from '../../hooks/useBeforeUnload'
import {
  validateBankRoutingNumber,
  validateEmail,
  validateRequiredField,
} from '../../utils/validators'
import { createPaymentMethodTabs } from '../../constants/paymentResults'
import {
  getCardPaymentToken,
  validateCreditCardFields,
} from '../../components/paymentType/CardIntegration'
import { useRequest } from '../../hooks/useRequest'
import { useNavigate, useParams } from 'react-router-dom'
import { GET } from '../../constants/contacts'
import { useCurrentVendor } from '../../hooks/useCurrentVendor'
import OperationResult from '../../components/operationResult/OperationResult'
import { operationResultMods, paymentStatus } from '../../constants/operationResults'
import { appendFormData, camelToSnake } from '../../utils/utils'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import axios from 'axios'
import { useNotifications } from '../../hooks/useNotifications'
import PropTypes from 'prop-types'
import { getBrowserInfo } from '../../utils/getBrowserInfo'
import CustomerPaymentFormDesktop from './CustomerPaymentFormDesktop'
import CustomerPaymentFormMobile from './CustomerPaymentFormMobile'
import { Button, Dialog, Flex, Text } from '../../ui-kit'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import fontWeight from '../../ui-kit/fontWeight'
import { denormalizePhoneNumber, normalizePhone } from '../../utils'
import getVendorSlug from '../../utils/getVendorSlug'
import { useCustomQuery } from '../../hooks/useCustomQuery'

const CustomerPaymentNew = ({ isPaymentRequest }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isMobile } = getBrowserInfo()
  const vendorData = useCurrentVendor()
  const vendorSlug = getVendorSlug()
  const { hashid } = useParams()
  const onFailed = useCallback((error) => {
    if (error) {
      setOperationResult(operationResultMods.LINK_NOT_AVAILABLE)
    }
  }, [])
  const { data } = useCustomQuery({
    queryOptions: {
      enabled: !!vendorSlug && !!hashid,
      queryFn: () => axios.get(`${vendorSlug}/guest/payment_requests/${hashid}`),
    },
    onFailed,
    rollbarOptions: { operationName: 'payments_request', target: 'customerPayment' },
  })
  const paymentAmount = data?.amountCents

  const [formData, setFormData] = useState(null)
  const [, setPaymentMethod] = useState(null)
  const [paymentUuid, setPaymentUuid] = useState(null)
  const [activeTab, setActiveTab] = useState(
    vendorData.creditCardFeePercentage ? createPaymentMethodTabs.ACH : createPaymentMethodTabs.CARD,
  )
  const [isFormDirty, setDirtyFormState] = useState(null)
  const [operationResult, setOperationResult] = useState(null)
  const [paymentStatusRefreshCounter, setPaymentStatusRefreshCounter] = useState(0)
  const [isContactUsModalOpened, setIsContactUsModalOpened] = useState(false)
  const defaultPaymentDetails = useMemo(
    () => ({
      paymentId: null,
      createdBy: null,
      companyName: null,
      paymentDate: null,
      accountNumber: null,
      paymentAmount: null,
      paymentMethodTitle: null,
    }),
    [],
  )
  const [paymentDetails, setPaymentDetails] = useState(defaultPaymentDetails)

  const onCreatePaymentMethod = useCallback((paymentMethod) => {
    if (!paymentMethod) {
      return
    }
    setPaymentMethod(paymentMethod)
  }, [])

  const locationsList = useMemo(
    () =>
      vendorData.locations?.map?.((item) => ({
        value: item.id,
        label: item.name,
        testData: item.name,
      })) || [],
    [vendorData?.locations],
  )

  const tabsMap = useMemo(() => {
    const tabs = vendorData.creditCardFeePercentage
      ? [createPaymentMethodTabs.ACH, createPaymentMethodTabs.CARD]
      : [createPaymentMethodTabs.CARD, createPaymentMethodTabs.ACH]
    return tabs.map((tab) => ({
      label: t(tab),
      onClick: () => setActiveTab(tab),
      active: activeTab === tab,
    }))
  }, [activeTab])

  const initialValues = useMemo(
    () => ({
      paymentRequestId: hashid,
      companyName: '',
      accountNumber: '',
      amountCents: paymentAmount ? paymentAmount / 100 : '',
      locationId: null,
      cardPaymentMethod: { zipCode: '' },
      achPaymentMethod: {
        accountHolderName: '',
        routingNumber: '',
        accountNumber: '',
        checkType: 'personal',
        accountType: 'checking',
      },
      payeeEmail: '',
      memo: data?.remittanceMemo || '',
    }),
    [paymentAmount],
  )

  useEffect(() => {
    setFormData(new FormData())
  }, [])

  const [submit, { loading: paymentInProgress }] = useCustomMutation({
    onCompleted: (response) => {
      processPaymentResult(response)
    },
    onFailed: (error) => {
      processPaymentResult(null, error)
    },
    rollbarOptions: { operationName: 'create_guest_payment_transaction', target: 'PaymentsTab' },
    mutationOptions: {
      mutationFn: () => {
        return axios.post(`${vendorData?.slug}/guest/payment_transactions`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          isFromData: true,
        })
      },
      mutationKey: ['guest_payment_transaction'],
    },
  })
  const { newNotification } = useNotifications()

  const handleSubmit = async (values) => {
    let data = null

    if (activeTab === createPaymentMethodTabs.CARD) {
      data = await getCardPaymentToken()
      if (!data || data.response_payload?.status === 'fail') {
        return newNotification({
          error: t('creditCardError'),
        })
      }
    }
    const { amountCents, achPaymentMethod, cardPaymentMethod, ...rest } = values
    const amountCentsValue =
      typeof amountCents === 'string' ? Number(amountCents.replaceAll(',', '')) : amountCents

    const variables = {
      ...rest,
      paymentMethodConfigurationId:
        activeTab === createPaymentMethodTabs.CARD
          ? vendorData?.creditCardPaymentMethodConfiguration?.id
          : vendorData?.achPaymentMethodConfiguration?.id,
      amountCents: Math.round(amountCentsValue * 100),
      paymentMethodData:
        activeTab === createPaymentMethodTabs.ACH
          ? camelToSnake(achPaymentMethod)
          : { ...camelToSnake(cardPaymentMethod), response_payload: data },
    }
    if (values.payeePhoneNumber) {
      variables.payeePhoneNumber = denormalizePhoneNumber(values.payeePhoneNumber)
    }
    appendFormData(formData, { payment_transaction: camelToSnake(variables) })

    await submit()
  }

  useEffect(() => {
    let timeoutId = null

    const callback = async () => {
      if (paymentStatusRefreshCounter === 0) {
        return
      }

      const { data, error } = await useRequest({
        url: `${vendorData?.slug}/guest/payment_transactions/${paymentUuid}`,
        method: GET,
        navigate,
      })
      processPaymentResult(data, error)

      if (data?.status !== paymentStatus.ENQUEUED && data?.status !== paymentStatus.PROCESSING) {
        return
      }

      timeoutId = setTimeout(() => {
        setPaymentStatusRefreshCounter((prevState) => prevState + 1)
      }, 3000)
    }
    callback()

    return () => {
      clearTimeout(timeoutId)
    }
  }, [paymentStatusRefreshCounter])
  const processPaymentResult = (payment, error) => {
    if (error) {
      setPaymentStatusRefreshCounter(0)
      setOperationResult(operationResultMods.SERVER_ERROR)
    }
    if (
      payment?.status === paymentStatus.ENQUEUED ||
      payment?.status === paymentStatus.PROCESSING
    ) {
      setPaymentUuid(payment?.uuid)
      if (!paymentStatusRefreshCounter) {
        setPaymentStatusRefreshCounter((prevState) => prevState + 1)
      }
      setOperationResult(operationResultMods.LOADING)
      return
    }
    if (payment?.status === paymentStatus.SUBMITTED || payment?.status === paymentStatus.PAID) {
      setPaymentStatusRefreshCounter(0)
      setPaymentDetails({
        paymentId: payment.id,
        createdBy: payment.payeeName,
        accountName: payment.companyName,
        paymentDate: payment.createdAt,
        accountNumber: payment.accountNumber,
        paymentAmount: payment.amountCents,
        chargedAmountCents: payment.chargedAmountCents,
        paymentMethodTitle: payment.paymentMethod?.title,
      })
      if (activeTab === createPaymentMethodTabs.CARD) {
        setOperationResult(operationResultMods.CREDIT_CARD_SUCCESS)
      } else {
        setOperationResult(operationResultMods.ACH_SUCCESS)
      }
      return
    }
    if (payment?.status === paymentStatus.FAILED) {
      setPaymentStatusRefreshCounter(0)
      if (activeTab === createPaymentMethodTabs.CARD) {
        setOperationResult(operationResultMods.CREDIT_CARD_FAILURE)
      } else {
        setOperationResult(operationResultMods.ACH_FAILURE)
      }
    }
  }

  const handleContactUsClick = () => {
    setIsContactUsModalOpened(true)
  }

  const handleActionClick = useCallback(() => {
    setFormData(new FormData())
    if (
      (operationResult === operationResultMods.ACH_SUCCESS ||
        operationResult === operationResultMods.CREDIT_CARD_SUCCESS) &&
      paymentAmount
    ) {
      navigate(`${vendorSlug}/create-payment`)
    }
  }, [paymentAmount, vendorSlug, operationResult])

  return (
    <>
      {!operationResult && (
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          render={({ handleSubmit, dirty }) => {
            useBeforeUnload({ when: dirty })
            dirty !== isFormDirty && setDirtyFormState(dirty)

            return isMobile ? (
              <CustomerPaymentFormMobile
                activeTab={activeTab}
                formData={formData}
                handleSubmit={handleSubmit}
                isPaymentRequest={isPaymentRequest}
                locationsList={locationsList}
                onCreatePaymentMethod={onCreatePaymentMethod}
                paymentInProgress={paymentInProgress}
                remittanceMemo={data?.remittanceMemo}
                tabsMap={tabsMap}
              />
            ) : (
              <CustomerPaymentFormDesktop
                activeTab={activeTab}
                formData={formData}
                handleSubmit={handleSubmit}
                isPaymentRequest={isPaymentRequest}
                locationsList={locationsList}
                onCreatePaymentMethod={onCreatePaymentMethod}
                paymentInProgress={paymentInProgress}
                remittanceMemo={data?.remittanceMemo}
                tabsMap={tabsMap}
              />
            )
          }}
          validate={(values) => {
            const errors = {
              accountNumber: validateRequiredField(values.accountNumber),
              companyName: validateRequiredField(values.companyName),
              payeeName: validateRequiredField(values.payeeName),
              amountCents: validateRequiredField(values.amountCents),
              payeeEmail:
                validateRequiredField(values.payeeEmail) || validateEmail(values.payeeEmail),
              locationId: validateRequiredField(values.locationId),
            }
            if (activeTab === createPaymentMethodTabs.ACH) {
              const achValues = values?.achPaymentMethod
              errors.achPaymentMethod = {
                accountHolderName: validateRequiredField(achValues?.accountHolderName),
                routingNumber:
                  validateRequiredField(achValues?.routingNumber) ||
                  validateBankRoutingNumber(achValues?.routingNumber),
                accountNumber: validateRequiredField(achValues?.accountNumber),
              }
            } else {
              errors.cardPaymentMethod = validateCreditCardFields(values.cardPaymentMethod)
            }
            return errors
          }}
          validateOnBlur={false}
        />
      )}
      {!operationResult && isMobile && (
        <Button
          className="mb-5"
          label={t('contactUs')}
          onClick={handleContactUsClick}
          variant={buttonsVariants.SECONDARY}
        />
      )}
      {isMobile && (
        <Dialog
          isOpened={isContactUsModalOpened}
          setIsOpened={setIsContactUsModalOpened}
          title={t('contactUs')}
          isMobile>
          <Flex column>
            <Text
              className="py-4 underline"
              fontWeight={fontWeight.MEDIUM}
              onClick={() => (window.location.href = `tel:${vendorData.phoneNumber}`)}>
              {t('callUs')}: {normalizePhone(vendorData.phoneNumber)}
            </Text>
            <a className="py-4 no-underline" href={`mailto:${vendorData.supportEmail}`}>
              <Text className="underline" fontWeight={fontWeight.MEDIUM}>
                {t('emailUs')}: {vendorData.email}
              </Text>
            </a>
          </Flex>
        </Dialog>
      )}
      {operationResult && (
        <OperationResult
          data={paymentDetails}
          mode={operationResult}
          onActionClick={handleActionClick}
          setMode={setOperationResult}
          isAnonymous
        />
      )}
    </>
  )
}

CustomerPaymentNew.propTypes = {
  isPaymentRequest: PropTypes.bool,
}
export default CustomerPaymentNew
