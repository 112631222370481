import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import NotFound from './pages/NotFound'
import { Settings } from './pages/settings'
import { Reports } from './pages/reports'
import appConfig from './configs/appConfig'
import Notification from './ui-kit/components/notifications/Notification'
import Payments from './pages/payments/Payments'
import MainLayout from './components/main/MainLayout'
import AnonymousLayout from './pages/mainLayouts/AnonymousLayout'
import CustomerPayment from './pages/customerPayment/CustomerPayment'
import VendorSlugRoute from './pages/mainLayouts/VendorSlugRoute'
import PrivateRoute from './auth/PrivateRoute'
import PublicRoute from './auth/PublicRoute'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import NavigateWithVendor from './pages/mainLayouts/NavigateWithVendor'
import Login from './pages/auth/Login'
import ResetPassword from './pages/auth/ResetPassword'
import SetNewPassword from './pages/auth/SetNewPassword'
import Invitation from './pages/auth/Invitation'
import { MyProfile } from './pages/myProfile'
import CustomerPaymentNew from './pages/customerPaymentNew/CustomerPaymentNew'
import InvalidToken from './pages/auth/InvalidToken'

const queryClient = new QueryClient()

const App = () => {
  useEffect(() => {
    document.title = 'Merchant Suppli'

    if (appConfig.CHAT_SUPPORT === 'true') {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.id = 'ze-snippet'
      script.src =
        'https://static.zdassets.com/ekr/snippet.js?key=5b3a194e-0479-47be-95e1-ab3ab24ef3dc'
      document.body.appendChild(script)
    }
  }, [])

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <VendorSlugRoute>
          <Routes>
            <Route element={<NotFound />} path="*" />

            <Route element={<PublicRoute />}>
              <Route element={<Login />} path="/auth/login" exact />
              <Route element={<ResetPassword />} path="/auth/login/reset_password" />
              <Route element={<InvalidToken />} path="/auth/login/invalid_token" />
              <Route element={<SetNewPassword />} path="/auth/password_reset/:resetPasswordToken" />
              <Route element={<Invitation />} path="/auth/invitations/:invitationToken" />
              <Route element={<AnonymousLayout />}>
                <Route element={<CustomerPayment />} path="/create-payment/*" />
                <Route
                  element={<CustomerPayment isPaymentRequest />}
                  path="/payment-request/:paymentAmount"
                />
                <Route
                  element={<CustomerPaymentNew isPaymentRequest />}
                  path="/payment-requests/:hashid"
                />
              </Route>
            </Route>

            <Route element={<PrivateRoute />}>
              <Route element={<MainLayout />}>
                <Route element={<MyProfile />} path="/myprofile" />
                <Route element={<Payments />} path="/payments/*" />
                <Route element={<Reports />} path="/reports" />
                <Route element={<Settings />} path="/settings/*" />
                <Route element={<NavigateWithVendor to="/payments" replace />} path="/" />
              </Route>
            </Route>
          </Routes>
          <Notification />
        </VendorSlugRoute>
      </QueryClientProvider>
    </BrowserRouter>
  )
}

export default App
